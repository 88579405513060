import * as React from 'react'
import { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import LeaderboardTable from '../components/leaderboardTable'
import { Select } from '@chakra-ui/react'

const LeaderboardPage = props => {
  const [highscoreType, setHighscoreType] = useState(
    props.data.allHighscoreType.nodes[0]
  )

  // TODO: make this dynamic
  const scopes = [
    {
      name: 'Season 25',
      id: '3',
    },
    {
      name: 'Season 24',
      id: '2',
    },
    {
      name: 'Season 23',
      id: '1',
    },
    {
      name: 'Season 22',
      id: '0',
    },
    {
      name: 'All Time',
      id: '-1',
    },
  ]

  const [scope, setScope] = useState(scopes[0])

  function selectHighscoreType(id) {
    props.data.allHighscoreType.nodes.forEach(node => {
      if (node.id === id) {
        setHighscoreType(node)
        console.log(node)
      }
    })
  }

  function selectScope(id) {
    scopes.forEach(scope => {
      if (scope.id === id) {
        setScope(scope)
      }
    })
  }

  // TODO: implement another select for filtering by season or total (maybe include highscore type "log")
  return (
    <Layout>
      <Seo title="Leaderboard" />
      <div style={{ display: 'flex', gap: '0.5rem' }}>
        <Select
          //variant=""
          marginBottom="2rem"
          flex="150%"
          value={highscoreType.id}
          onChange={e => selectHighscoreType(e.target.value)}
        >
          {props.data.allHighscoreType.nodes.map(node => (
            <option value={node.id} key={node.id}>
              {node.name}
            </option>
          ))}
        </Select>
        {highscoreType.name !== "180's Logbook" && (
          <Select
            //variant=""
            marginBottom="2rem"
            value={scope.id}
            onChange={e => selectScope(e.target.value)}
          >
            {scopes.map(scope => (
              <option value={scope.id} key={scope.id}>
                {scope.name}
              </option>
            ))}
          </Select>
        )}
      </div>
      <LeaderboardTable highscoreType={highscoreType} scope={scope} />
    </Layout>
  )
}

// fetch highscore types
export const query = graphql`
  query highscoreTypesQuery {
    allHighscoreType(sort: { order: ASC, fields: name }) {
      nodes {
        id
        name
        type
        field_name
      }
    }
  }
`
export default LeaderboardPage
